import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef } from '@angular/core';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';


import { CoreConfigService } from '@core/services/config.service';

@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  coreConfig: any;
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(    private _coreMenuService: CoreMenuService, private _coreConfigService: CoreConfigService, private _elementRef: ElementRef) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });


        // Get current menu
        this._coreMenuService.onMenuChanged
        .pipe(
          filter(value => value !== null),
          takeUntil(this._unsubscribeAll)
        )
        .subscribe(() => {
          this.menu = this._coreMenuService.getCurrentMenu();
        });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


const apiUrl = environment.API_URI;

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  getUsers(){
    return this.http.get(`${apiUrl}/users`);
  }

  changeLogo(id, logo) {
    return this.http.put(`${apiUrl}/users/change-logo/${id}`,logo);
  }

  getOneUser(id){
    return this.http.get(`${apiUrl}/users/${id}`);
  }

  updateUser(id, body) {
    return this.http.put(`${apiUrl}/users/${id}`,body);
  }

  resetPassForUser(id, body) {
    return this.http.put(`${apiUrl}/users/reset-pass/${id}`,body);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ResponseI } from '../../../../core/models/response';
import { environment } from '../../../../../environments/environment';

const apiUrl = environment.API_URI;

@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  rows: any;
  onInvoiceListChanged: BehaviorSubject<any>;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }

  constructor(private http: HttpClient) {
   }

   private getHeaders(): HttpHeaders {
    const token = localStorage.getItem("user_token");
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  getUserAdmin(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${apiUrl}/user-admin`, this.httpOptions).subscribe((response: any) => {
        resolve(response.data);
      }, reject);
    });
  }

  getAllUsersAdmin() {
    return this.http.get(`${apiUrl}/user-admin`);
  }

  getUserAdminByToken(token){
    return this.http.get(`${apiUrl}/user-admin/activate/${token}`, this.httpOptions);
  }

  getUserAdminById(id): Observable<ResponseI> {
    return this.http.get<ResponseI>(`${apiUrl}/user-admin/${id}`, this.httpOptions);
  }

  getOneUserAdmin(id){
    return this.http.get<ResponseI>(`${apiUrl}/user-admin/${id}`, this.httpOptions);
  }

  createUserAdmin(userAdmin): Observable<ResponseI> {
    return this.http.post<ResponseI>(`${apiUrl}/user-admin`, userAdmin, this.httpOptions);
  }

  updateUser(id, userToUpdate) {
    return this.http.put(`${apiUrl}/api/users/${id}`, userToUpdate, {headers: this.getHeaders()});
  }

  deleteUserAdmin(id): Observable<ResponseI> {
    return this.http.delete<ResponseI>(`${apiUrl}/user-admin/${id}`, this.httpOptions);
  }

  getUserById(id){
    return this.http.get(`${apiUrl}/api/users/${id}`, {headers: this.getHeaders()});
  }

  getCompanyById(id){
    return this.http.get(`${apiUrl}/api/companies/${id}`, {headers: this.getHeaders()});
  }

  getCompanyType(id){
    return this.http.get(`${apiUrl}/company-type/${id}`, this.httpOptions);
  }

  getSuperUser(id){
    return this.http.get(`${apiUrl}/super-user/${id}`, this.httpOptions);
  }
}

<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a
    class="nav-link"
    (click)="markNotificationsAsRead()"
    ngbDropdownToggle
    id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">
      {{ newNotificationsCounter }}</span
    ></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificaciones</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ newNotificationsCounter }} New
        </div>
      </div>
    </li>
    <li
      *ngFor="let notification of notifications$ | async"
      class="dropdown-menu-header"
    >
      <div class="dropdown-header">
        <h5 class="notification-title mb-0 mr-auto">
          {{ notification.title }}
        </h5>
          <div class="badge badge-pill badge-light-primary mt-1">
            {{ notification.message }}
          </div>
          <div class="mt-1 text-right">
            {{ formatTimeAgo(notification.createdAt) }}
          </div>
      </div>
    </li>
    <li *ngFor="let notification of notifications" class="dropdown-menu-header">
      <div class="dropdown-header">
        <h5 class="notification-title mb-0 mr-auto">
          {{ notification.title }}
        </h5>
          <div class="badge badge-pill badge-light-primary mt-1">
            {{ notification.message }}
          </div>
          <div class="mt-1 text-right">
            {{ formatTimeAgo(notification.createdAt) }}
          </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" *ngFor="let notification of notifications">
        <div
          *ngIf="
            (notification.notifications_status == 0 &&
              notification.notifications_receiver == userId) ||
            notification.notifications_alert == 'Nueva demanda' ||
            notification.alert == 'Nuevo proveedor'
          "
          class="media d-flex align-items-start"
          ngbDropdownToggle
          (click)="redirect(notification)"
        >
          <div
            class="media-left"
            *ngIf="notification.notifications_alert == 'mensaje'"
          >
            <div class="avatar">
              <img
                src="assets/images/logo/mailNot.png"
                alt=""
                width="32"
                height="32"
              />
            </div>
          </div>
          <div
            class="media-left"
            *ngIf="notification.notifications_alert == 'Nueva demanda'"
          >
            <div class="avatar">
              <img
                src="assets/images/logo/logNot.jpg"
                alt=""
                width="32"
                height="32"
              />
            </div>
          </div>
          <div
            class="media-left"
            *ngIf="notification.notifications_alert == 'Nueva Negociación'"
          >
            <div class="avatar">
              <img
                src="assets/images/logo/logNot.jpg"
                alt=""
                width="32"
                height="32"
              />
            </div>
          </div>
          <div
            class="media-left"
            *ngIf="
              notification.notifications_alert ==
              'No has aceptado una negociación que esta por finalizar'
            "
          >
            <div class="avatar">
              <img
                src="assets/images/logo/imgAlert.png"
                alt=""
                width="32"
                height="32"
              />
            </div>
          </div>
          <div
            class="media-left"
            *ngIf="notification.notifications_alert == 'Nuevo proveedor'"
          >
            <div class="avatar">
              <img
                src="assets/images/logo/newProviderNot.jpg"
                alt=""
                width="32"
                height="32"
              />
            </div>
          </div>
          <div
            *ngIf="notification.notifications_alert == 'mensaje'"
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }} de la sala:
              {{ notification.notifications_room || notification.room_id }}
            </p>
            <small class="notification-text"
              >{{ notification.users_name || notification.users_company_name }}:
              {{
                notification.notification ||
                  notification.notifications_description
              }}</small
            >
          </div>
          <div
            *ngIf="notification.notifications_alert == 'Nueva demanda'"
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }}
            </p>
            <small class="notification-text">{{
              notification.notifications_description
            }}</small>
          </div>
          <div
            *ngIf="notification.notifications_alert == 'Nueva Negociación'"
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }}
            </p>
            <small class="notification-text">{{
              notification.notifications_description
            }}</small>
          </div>
          <div
            *ngIf="notification.notifications_alert == 'Nuevo proveedor'"
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }}
            </p>
            <small class="notification-text">{{
              notification.notifications_description
            }}</small>
          </div>
          <div
            *ngIf="
              notification.notifications_alert ==
              'alguien aceptó una negociación'
            "
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }}
            </p>
            <small class="notification-text">{{
              notification.notifications_description
            }}</small>
          </div>
          <div
            *ngIf="
              notification.notifications_alert ==
              'No has aceptado una negociación que esta por finalizar'
            "
            class="media-body"
          >
            <p class="media-heading" [innerHTML]="">
              {{ notification.notifications_alert }}
            </p>
            <small class="notification-text">{{
              notification.notifications_description
            }}</small>
          </div>
        </div></a
      >
    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <!--/ Notifications footer -->
  </ul>
</li>

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject } from "rxjs";
import { Console } from "console";

const apiUrl = environment.API_URI;

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  //NOTIFICACIONES
  private _notifications$ = new BehaviorSubject<any[]>([]);
  public notifications$ = this._notifications$.asObservable();

  //ROOMS
  private _room$ = new BehaviorSubject<string | null>(null);
  private room$ = this._room$.asObservable();

  rooms: any = [];
  userId: any = localStorage.getItem("user_id");

  constructor(private http: HttpClient, private socket: Socket) {
    //recibir notificaciones
    this.listenForNotifications().subscribe((notification: any) => {
      this.setNotifications(notification);
    });
  }

  public setNotifications(notification: any): void {
    if (notification.user != this.userId)
    {
      const current = this._notifications$.getValue();
      const state = [notification].concat(current); // Inserta al inicio
      this._notifications$.next(state);
    }
  }

  public initNotifications(): void {
    this._notifications$.next([]);
  }

  getAllUserNotifications(user_id) {
    //Traer todas las notificaciones del usuario
    return this.http.get(`${apiUrl}/api/notifications/${user_id}`)
  }

  joinUserToAllItsRooms(user_id) {
    //Traer todas las rooms del usuario
    this.http.get(`${apiUrl}/api/notifications/rooms/${user_id}`)
    .subscribe((res: any) => {
      this.rooms = res;
      this.rooms.forEach(room => {
        this.socket.emit("notifications_event_rejoin", room._id);
      });
    });
  }

  getConversationMessages(conversation_id) {
    return this.http.get(
      `${apiUrl}/api/message/by-id-conversation/${conversation_id}`
    );
  }

  createRoom(roomId: string, userId: any): void {
    //Crear la sala
    this.socket.emit("notifications_event_create", roomId);
    //Agregar la suscripcion a la sala a la db
    console.log("Post de create room con id: " + roomId + " y user: " + userId);
    this.http.post(`${apiUrl}/api/notifications/${roomId}/${userId}`, {})
    .subscribe(
      data => {
        console.log('Success:', data);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  /**
   * Se une a la sala de notificaciones
   * @param id id de la sala a la que se quiere unir
   * @returns void
   */
  joinRoom(room: string, firstname: string, lastname:string, userId: any): void {
    //Unirse a la sala y notificar
    console.log("ENTRA A JOIN ROOM CON ID: " + room + " y user: " + userId + " y nombre: " + firstname + " " + lastname);
    this.socket.emit("notifications_event_join", { room, firstname, lastname, userId });
    //Agregar la suscripcion a la sala a la db
    this.http.post(`${apiUrl}/api/notifications/${room}/${userId}`, {})
    .subscribe(
      data => {
        console.log('Success:', data);
      },
      error => {
        console.error('Error:', error);
      }
    );
    
  }

  /**
   * Abandona la sala de notificaciones actual
   * @returns void
   */
  leaveRoom(): void {
    const room = this._room$.getValue();
    //Notificar que se abandonó la sala
    this.socket.emit("notifications_event_leave", room);
    //Eliminar la suscripcion a la sala de la db
  }

  listenForNotifications() {
    return this.socket.fromEvent("new_notification");
  }

  sendNotification(message: any) {
    const roomCurrent = this._room$.getValue();//TODO: js, ts, node
    if (roomCurrent) {
      this.socket.emit('event_notification', message); //TODO FRONT
    }
    return this.http.post(`${apiUrl}/api/message/`, message);
  }

  markNotificationsAsRead(notifications, userId) {
    //Marcar como leidas las notificaciones
    notifications.forEach(notification => {
      if (notification._id)
      {
        console.log("PUT de markNotificationsAsRead con id: " + notification._id + " y user: " + userId);
        this.http.put(`${apiUrl}/api/notifications/${notification._id}/${userId}`, {})
        .subscribe(
          data => {
            console.log('Success:', data);
          },
          error => {
            console.error('Error:', error);
          }
        ); 
      }
    });
  }

}

import { Component, EventEmitter, Input, OnInit,  Output,  ViewChild, ViewEncapsulation} from "@angular/core";
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableComponent implements OnInit {

  // inputs
  @Input() rows = [];
  @Input() tempData = [];
  @Input() tempFilterData = [];
  @Input() customAdd: string = 'Agregar';
  @Input() customName: string = 'Componente'; 
  @Input() type: string; 
  @Input() id;

  // Outputs
  @Output() createClicked: EventEmitter<any> =
      new EventEmitter<any>();

  @Output() deleteClicked: EventEmitter<string> =
      new EventEmitter<string>();

  @Output() editClicked: EventEmitter<any> =
      new EventEmitter<any>();

  @Output() showClicked: EventEmitter<any> =
      new EventEmitter<any>();

  @Output() subscriptionList: EventEmitter<any> =
      new EventEmitter<any>();
   
  // public
  public data: any;
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public selectStatus: any = [
    { name: 'All', value: '' },
    { name: 'Downloaded', value: 'Downloaded' },
    { name: 'Draft', value: 'Draft' },
    { name: 'Paid', value: 'Paid' },
    { name: 'Partial Payment', value: 'Partial Payment' },
    { name: 'Past Due', value: 'Past Due' },
    { name: 'Sent', value: 'Sent' }
  ];
  
  public selectedStatus = [];
  public searchValue = '';
  
  // decorator
  @ViewChild(DatatableComponent) table: DatatableComponent;
  
  // private
  public previousStatusFilter = '';
  
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {CalendarService} _calendarService
   */
  constructor(
    private router: Router
    ) {
  }
  
  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  
  /**
   * filterUpdate
   *
   * @param event
   */
  filterUpdate(event) {
    // Reset ng-select on search
    this.selectedStatus = this.selectStatus[0];
  
    const val = event.target.value.toLowerCase();
  
    // filter our data
    console.log(this.tempData);
    
    const temp = this.tempData.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
  
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  
  /**
   * Filter By Roles
   *
   * @param event
   */
  filterByStatus(event) {
    const filter = event ? event.value : '';
    this.previousStatusFilter = filter;
    this.tempFilterData = this.filterRows(filter);
    this.rows = this.tempFilterData;
  }
  
  /**
   * Filter Rows
   *
   * @param statusFilter
   */
  filterRows(statusFilter): any[] {
    // Reset search on select change
    this.searchValue = '';
  
    statusFilter = statusFilter.toLowerCase();
  
    return this.tempData.filter(row => {
      const isPartialNameMatch = row.invoiceStatus.toLowerCase().indexOf(statusFilter) !== -1 || !statusFilter;
      return isPartialNameMatch;
    });
  }

  onCreateClicked(){
    this.createClicked.emit();
  }
  onShowClicked(item){
    this.showClicked.emit(item);
  }
  onDeleteClicked(id){
    this.deleteClicked.emit(id);
  }
  onEditClicked(item){
    this.editClicked.emit(item);
  }
  onSubscriptionList(item){
    this.subscriptionList.emit(item);
  }
  
  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe config change
  }
  
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
  }
  }







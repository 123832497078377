<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Invoice List -->
    <section class="invoice-list-wrapper">
      <div class="card">
        <!-- Invoice List Header -->
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <div class="d-flex align-items-center">
                <label class="d-flex align-items-center"
                  >Show
                  <select class="form-control mx-25" [(ngModel)]="selectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
                <button (click) = "onCreateClicked()" class="btn btn-primary ml-2" rippleEffect>{{customAdd}}</button>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-4 col-12 d-flex justify-content-start justify-content-md-end align-items-center offset-lg-2"
          >
            <div class="row pr-1 pb-1 pb-md-0 align-items-center w-100">
              <div class="col-10 col-md-6 pr-0" >
                <label class="d-flex align-items-center mb-0 ml-1 ml-md-0"
                  ><input
                    [(ngModel)]="searchValue"
                    name="searchValue"
                    type="search"
                    class="form-control"
                    placeholder="Buscar..."
                    (keyup)="filterUpdate($event)"
                /></label>
              </div>
              <div class="col-10 col-md-6 mt-2 ml-1 ml-md-0 mt-md-0">
                <fieldset class="form-group mb-0">
                
                </fieldset>
              </div>
            </div>
          </div>
        </div>
        <!--/ Invoice List Header -->

        <!-- Invoice List Table -->
        <ngx-datatable
          [rows]="rows"
          [rowHeight]="50"
          class="bootstrap core-bootstrap"
          [limit]="selectedOption"
          [columnMode]="ColumnMode.force"
          [headerHeight]="50"
          [footerHeight]="50"
          [scrollbarH]="true"
        >
          <ngx-datatable-column name="#" prop="id" [width]="50">
            <ng-template let-id="value" ngx-datatable-cell-template>
              <a class="font-weight-bold">#{{ id }}</a>
            </ng-template>
          </ngx-datatable-column>
        <!--   <ngx-datatable-column name="Estado" prop="invoiceStatus" [width]="50">
            <ng-template let-row="row" let-invoiceStatus="value" ngx-datatable-cell-template>

              <div
                class="avatar avatar-status"
                [ngClass]="{
                  'bg-light-secondary': row.invoiceStatus == 'Sent',
                  'bg-light-success': row.invoiceStatus == 'Paid',
                  'bg-light-primary': row.invoiceStatus == 'Draft',
                  'bg-light-warning': row.invoiceStatus == 'Partial Payment',
                  'bg-light-info': row.invoiceStatus == 'Downloaded',
                  'bg-light-danger': row.invoiceStatus == 'Past Due'
                }"
              >
                <ng-template #tipContent
                  ><span
                    >
                    <span class="font-weight-bold">Estado:</span> {{ row.telephone }}
                    </span
                  ></ng-template
                >
                <span class="avatar-content" placement="top" [ngbTooltip]="tipContent" container="body">
                  <i
                    class="feather avatar-icon font-medium-1"
                    [ngClass]="{
                      'icon-send': row.idel == 0,
                      'icon-check-circle': row.idel == 'Paid',
                      'icon-save': row.idel == 'Draft',
                      'icon-pie-chart': row.idel == 'Partial Payment',
                      'icon-arrow-down-circle': row.idel == 'Downloaded',
                      'icon-info': row.idel == 'Past Due'
                    }"
                  ></i
                ></span>
              </div>
            </ng-template>
          </ngx-datatable-column>-->
          <ngx-datatable-column name="{{customName}}" prop="row.name" [width]="250">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div *ngIf="row.logo">
                  <img
                    class="rounded-circle mr-1"
                    src="{{ row.logo }}"
                    height="32"
                    width="32"
                    alt="datatable-avatar"
                  />
                </div>
                <ng-template #customAvatar>
                  <div
                    class="avatar mr-1 ml-0"
                    [ngClass]="{
                      'bg-light-secondary': row.idel == 0,
                      'bg-light-success': row.idel == 'Paid',
                      'bg-light-primary': row.idel == 'Draft',
                      'bg-light-warning': row.idel == 'Partial Payment',
                      'bg-light-info': row.idel == 'Downloaded',
                      'bg-light-danger': row.idel == 'Past Due'
                    }"
                  >
                    <div class="avatar-content">{{ row.name | initials }}</div>
                  </div>
                </ng-template>
                <div class="cell-line-height">
                  <span class="font-weight-bold d-block text-nowrap font-medium-1" *ngIf="row.type">{{ row.type }}</span>
                  <span class="font-weight-bold d-block text-nowrap font-medium-1">{{ row.name }}</span>
                  <span class="text-muted font-small-2"> @{{ row.name }}</span>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="Fecha de Creacion" prop="createdDate" [width]="100">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="cell-line-height">
                  <span class="font-weight-bold d-block text-nowrap font-medium-1">{{ row.create_at.split('T')[0] }}</span>
                </div>
            </ng-template> 
          </ngx-datatable-column>
          <ngx-datatable-column name="Fecha de Actualizacion" prop="UpdatedDate" [width]="100">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="cell-line-height">
                <span class="font-weight-bold d-block text-nowrap font-medium-1">{{ row.update_at.split('T')[0] }}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Acciones" [width]="40">
            <ng-template ngx-datatable-cell-template let-row="row">
              <div class="d-flex align-items-center col-actions">
                <a
                  (click) = "onShowClicked(row)"
                  class="mr-1"
                  container="body"
                  placement="top"
                  ngbTooltip="Ver {{customName}}"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                  </svg>
                </a>

                <a
                  (click) = "onSubscriptionList(row)"
                  class="mr-1"
                  container="body"
                  placement="top"
                  ngbTooltip="Ver lista de suscritos de {{customName}}"
                  >
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                  </svg>

                </a> 

               

                <a
                  (click) = "onEditClicked(row.id)"
                  class="mr-1"
                  container="body"
                  placement="top"
                  ngbTooltip="Editar {{customName}}"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                  </svg>
                </a>
                
                <a
                  (click) = "onDeleteClicked(row.id)"
                  class="mr-1"
                  container="body"
                  placement="top"
                  ngbTooltip="Eliminar {{customName}}"
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </a>
                <div ngbDropdown container="body">
                  <button ngbDropdownToggle type="button" class="btn icon-btn btn-sm hide-arrow px-0" rippleEffect>
                    <i data-feather="more-vertical" size="18" class="cursor-pointer"></i>
                  </button>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <!--/ Invoice List Table -->
      </div>
    </section>
    <!--/ Invoice List -->
  </div>
</div>

import {
  AfterViewInit,
  Component,
  OnInit,
  AfterViewChecked,
} from "@angular/core";

import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { Socket } from "ngx-socket-io";
import { exists } from "fs";
import { Router, RouterLink, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { HotelServices } from "app/modules/hotels/services/hotel/hotel.service";
import { ProviderService } from "../../../../modules/provider/services/provider/provider.service";
import { AdminUserService } from "app/modules/main/services/adminUserService/admin-user.service";
import { RoomService } from "app/modules/negociation/services/room/room.service";
import { ThrowStmt } from "@angular/compiler";
import { ChatServiceOld } from "../../../../modules/negociation/services/chatOld/chatOld.service";
import { Console } from "console";
import { formatDistanceToNow } from "date-fns";
import { es, th } from "date-fns/locale";

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

const hotelId = localStorage.getItem("hotel_id");

@Component({
  selector: "app-navbar-notification",
  templateUrl: "./navbar-notification.component.html",
})
export class NavbarNotificationComponent implements OnInit, AfterViewInit {
  //Notificaciones en tiempo real
  public notifications$ = this.notificationsService.notifications$;
  //Notificaciones antiguas traidas de la base de datos
  public notifications: any;
  notification: any = [];

  userType = localStorage.getItem("user_type");
  userId = localStorage.getItem("user_id");
  user = localStorage.getItem("user");
  rooms: any = [];
  data: any;
  notis: any = [];
  demandNotis: any = [];
  providerNotis: any = [];
  usersList: any = [];
  providersList: any = [];
  alertNotis: any;
  loaded: boolean;
  newNotificationsCounter: number = 0;
  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _notificationsService: NotificationsService,
    private route: Router,
    private notificationsService: NotificationsService
  ) {
    this.notifications = [];
    this.loaded = false;
  }
  ngAfterViewInit(): void {}
  usersInRoom: any;
  newNoti: any;
  roomsSubs: any = [];

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  AfterViewInit() {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.userId = localStorage.getItem("user_id");
    this.getNotificationsForUser();
    this.joinUserToAllItsRooms();
    this.notifications$.subscribe((socketNotifications) => {
    if (socketNotifications && socketNotifications.length > 0) {
      this.newNotificationsCounter++;
    }
    });
  }

  getNotificationsForUser() {
    //Traer todas las notificaciones de las rooms a las que esta asociado el usuario
    this.notificationsService.getAllUserNotifications(this.userId).subscribe(
      (data: any) => {
        this.notifications = data.reverse();

        //Sacar el numero de notificaciones nuevas para el usuario
        this.notifications.forEach((notification) => {
          if (!notification.readBy.includes(this.userId)) {
            this.newNotificationsCounter++;
          }
        });
        console.log("newNotificationsCounter:", this.newNotificationsCounter);
      },
      (error) => {
        console.error("Error getAllUserNotifications:", error);
      }
    );
    //Indicar que ya se trajeron las notificaciones
    this.loaded = true;
  }

  joinUserToAllItsRooms() {
    this.notificationsService.joinUserToAllItsRooms(this.userId);
  }

  formatTimeAgo(date: Date | number): string {
    return formatDistanceToNow(new Date(date), { addSuffix: true, locale: es });
  }

  markNotificationsAsRead() {
    if (this.loaded) {
      let allNotifications = this.notifications.concat(this.notifications$);
      this.notificationsService.markNotificationsAsRead(
        allNotifications,
        this.userId
      );
      this.newNotificationsCounter = 0;
    }
  }

}

import { CoreMenu } from '@core/types'
import adminMenu from '../../assets/menus/admin_menu.json';
import providerMenu from '../../assets/menus/provider.json';
import superMenu from '../../assets/menus/super_user.json';

let mainMenu = providerMenu;
if (localStorage.getItem('type') === 'super') {
  mainMenu = superMenu;
} else if (localStorage.getItem('type') === 'admin') {
    mainMenu = adminMenu;
}

export const menu: any[] = mainMenu
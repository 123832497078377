import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', //TODO: localhost:4200/
    loadChildren:() => import(`./modules/auth/auth.module`).then( m => m.AuthModule)
  },
  {
    path: 'main', //TODO: localhost:4200/
    loadChildren:() => import(`./modules/main/main.module`).then( m => m.MainModule)
  },
  {
    path:'negociation',
    loadChildren:() => import(`./modules/negociation/negociation.module`).then( m => m.NegociationModule)
  },
  {
    path:'provider',
    loadChildren:() => import(`./modules/provider/provider.module`).then( m => m.ProviderModule)
  },
  {
    path:'hotels',
    loadChildren:() => import(`./modules/hotels/hotels.module`).then( m => m.HotelsModule)
  },
  {
    path:'help',
    loadChildren:() => import(`./modules/help/help.module`).then( m => m.HelpModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled', // Add options right here
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, FormGroup } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { ContentHeaderModule } from './layout/components/content-header/content-header.module';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';
import { HelpComponent } from './modules/help/help.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';


const socketUrl = environment.SOCKET_URL;

//const config: SocketIoConfig = { url: "http://localhost:3001", options: {}} 
const config: SocketIoConfig = { url: "https://comprathr.com", options: {}} 

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxYoutubePlayerModule.forRoot(),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgSelectModule,
    /* RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }), */
    AppRoutingModule,
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // Socket Module
    SocketIoModule.forRoot(config),

    // App modules
    LayoutModule,
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    SharedModule,
    NgxDatatableModule,
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule { }

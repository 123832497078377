// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  AWS_ACCESS_KEY: 'AKIARMY3ZH7BFIWITBP6',
  AWS_SECRET_KEY: 'aUAIkg/4Lp9tFJm1QQjKgWIn22gRL3YHWxn1JxoF',
  //API_URI: 'http://localhost:3001',
  //SOCKET_URL:'http://localhost:3001',
  API_URI: 'https://comprathr.com',
  SOCKET_URL:'https://comprathr.com',
  aws: {
    accessKeyId: 'AKIARMY3ZH7BBWRUVTBO',
    secretAccessKey: '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',
    region : 'us-east-1',
    bucket: 'compra-colab',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
